import { decryptData, encryptData } from "config/config";
import { instance as axios, setMultiPartHeader } from "config/axiosInstance";
import { isLoggedIn } from "utils";

// ************************** Mentor Management***************************
// ************************** All Mentors***************************
export const getMentorsList = async (data) => {
  return decryptData(
    await axios.get(
      `/getMentorsList?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getSlotList = async (data) => {
  return decryptData(
    await axios.get(
      `/GetSlotList?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getMentorSlotBooking = async (data) => {
  return decryptData(
    await axios.get(
      `/getMentorSlotBooking?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getMentorSlotBookingbyMentorid = async (data) => {
  return decryptData(
    await axios.get(
      `/getMentorSlotBookingByMentorId?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const adminBookSlot = async (data) => {
  return decryptData(
    await axios.post(`/admin-book-slot`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};
export const adminBookAllSlot = async (data) => {
  return decryptData(
    await axios.post(`/admin-book-all-slot`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};

export const adminDeleteSlot = async (data) => {
  return decryptData(
    await axios.post(`/admin-delete-slot`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};

// ************************** My Appointment***************************
export const getMyAppointmentList = async (data) => {
  return decryptData(
    await axios.get(
      `/getMyAppointmentList?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getMeetingQuestionsList = async (data) => {
  return decryptData(
    await axios.get(
      `/getMinutesofMeetingQuestions?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// export const addMinutesOfMeeting = async (data) => {
//   return decryptData(
//     await axios.post(`/add-minutes-of-meeting`, {
//       reqData: encryptData({
//         ...data,
//         access_token: isLoggedIn(),
//       }),
//     })
//   );
// };
export const addMinutesOfMeeting = async (data) => {
  return decryptData(
    await axios.post(`/add-minutes-of-meeting`, data, setMultiPartHeader())
  );
};


// ********************************** Update My Appointment Student Details ***********************************************
export const getMyAppointmentStudentDetails = async (data) => {
  return decryptData(
    await axios.get(
      `/GetStudentMentorshipInfo?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const EditStudentMentorshipInfo = async (data) => {
  return decryptData(
    await axios.post(`/EditStudentMentorshipInfo`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};

export const getMeetingDetails = async (data) => {
  return decryptData(
    await axios.get(
      `/getAppointmentDetail?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// ************************** Upcomming Appointment***************************
export const getUpcomingAppointmentList = async (data) => {
  return decryptData(
    await axios.get(
      `/getUpcomingAppointmentList?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const addUpcommingMeetingLink = async (data) => {
  return decryptData(
    await axios.post(`/add-meeting-link`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};

// export const getUpcomingAppointmentStudentDetails = async (data) => {
//   return decryptData(
//     await axios.get(
//       `/getStudentDetails?reqData=${encryptData({
//         ...data,
//         access_token: isLoggedIn(),
//       })}`
//     )
//   );
// };



// ************************** Minutes Of Meeting Questions ***************************

export const addMinutesofMeetingQuestion = async (data) => {
  return decryptData(
    await axios.post(`/addMinutesofMeetingQuestion`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};

export const editMinutesofMeetingQuestion = async (data) => {
  return decryptData(
    await axios.post(`/editMinutesofMeetingQuestion`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};

export const updateStatusofMOMQuestions = async (data) => {
  return decryptData(
    await axios.post(`/updateStatusofMOMQuestions`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};
