import React, { useState, useEffect } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useFullPageLoader from "hooks/useFullPageLoader";
import toast from "react-hot-toast";
import ModalBox from "components/modal/ModalBox";
import { Button } from "components/Button/Button";
import { useParams } from "react-router-dom";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";



import { FormControl, InputLabel, MenuItem } from "@mui/material";
import { Select } from "components/Form";

import {
  getSlotList,
  getMentorsList,
  adminBookSlot,
  adminBookAllSlot,
  adminDeleteSlot,
  getMentorSlotBooking,
  getMentorSlotBookingbyMentorid
} from "services/mentorslots/mentorslot";
import { Link } from "react-router-dom";

const MySlots = () => {
  const [dates, setDates] = useState([]);
  const mentorid = localStorage.getItem("mentorid");
  const name = localStorage.getItem("mentorname");
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slottimes, setSlotTime] = useState(); // State for expired popup
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [selectedOption, setSelectedOption] = useState(""); // State for dropdown
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isbookAllSlotModalOpen, setBookAllSlotModalOpen] = useState(false);
  const [selectedMentor, setselectedMentor] = useState("");
  const [mentors, setMentors] = useState([]);
  const [bookedslots, setbookedslots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bookedSlots, setBookedSlots] = useState(new Set());
  const [slotTimeForAllSlot, setslotTimeForAllSlot] = useState(null);
  const [slotIdForAllSlot, setSlotIdslotIdForAllSlot] = useState(null);
  const [submitButtonElementloading, setSubmitLoading] = useMuiButtonLoader();
  const [bookedSlotsMap, setBookedSlotsMap] = useState(new Map()); // Map for grouped bookings
  const [bookedSlotsMapwithoutmentorid, setBookedSlotsMapwithoutmentorid] = useState(new Map()); // Map for grouped bookings
  // const colors = ["rgb(244 160 126)", "#f67280", "#c06c84", "#6c5b7b"]; // Color palette for multiple bookings
  const colors = ["#6c5b7b", "#c06c84", "#f67280", "rgb(244 160 126)"]; // Color palette for multiple bookings

  const datesPerPage = 7;


  const fetchslotTime = async () => {
    try {
      showLoader();
      const response = await getSlotList();
      if (response.code === 200) {
        setSlotTime(response.data.slots.rows);
      } else {
        toast.error(response.message || "Failed to fetch data");
      }
    } catch (error) {
      toast.error("An error occurred while fetching data");
    } finally {
      hideLoader();
    }
  };

  const fetchmentors = async () => {
    try {
      showLoader();
      const response = await getMentorsList();
      if (response.code === 200) {
        setMentors(response.data.mentors.rows);
      } else {
        toast.error(response.message || "Failed to fetch data");
      }
    } catch (error) {
      toast.error("An error occurred while fetching data");
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    const currentDates = dates.slice((currentPage - 1) * datesPerPage, currentPage * datesPerPage);
    fetchbookedslots(currentDates);
    fetchbookedslotswithoutmentorid(currentDates)
  }, [currentPage, dates]);


  const [selectedSlots, setSelectedSlots] = useState({});

  const handleCheckboxChange = (slotTime, slotId) => {
    const updatedSelectedSlots = { ...selectedSlots };
    if (updatedSelectedSlots[slotTime]) {
      delete updatedSelectedSlots[slotTime]; // Unselect the slot if already selected
    } else {
      updatedSelectedSlots[slotTime] = slotId;
      setBookAllSlotModalOpen(true);
      setslotTimeForAllSlot(slotTime)
      setSlotIdslotIdForAllSlot(slotId)
    }
    setSelectedSlots(updatedSelectedSlots); // Update the state with both slotTime and slotId
  };



  const handleCheckboxChangeSubmit = async () => {
    // Update the selected slots first
    setSubmitLoading(true)
    try {
      // Call API to add PG Master

      const data = {
        slotid: slotIdForAllSlot,
        slotname: slotTimeForAllSlot, // You might want to adjust this
        date: dates,
        mentorid: mentorid, // Directly using the selected mentor object
        mentorname: name,
      };

      const response = await adminBookAllSlot(data);

      if (response.code === 200) {
        toast.success("Slot Booked Successfully");
        setModalOpen(false);
        setselectedMentor("");
        setBookAllSlotModalOpen(false);
        setSelectedSlots({});
        fetchbookedslots(currentDates);
        setSubmitLoading(false)
      } else {
        toast.error(response.message);
        setSelectedSlots({});
        setSubmitLoading(false)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
      setSelectedSlots({});
      setSubmitLoading(false)
    } finally {
      setLoading(false);
      setSelectedSlots({});
      setSubmitLoading(false)
    }
  };

  const fetchbookedslots = async (datesArray) => {
    if (!datesArray || datesArray.length === 0) return;

    try {
      showLoader();
      const response = await getMentorSlotBookingbyMentorid({ dates: datesArray, mentorid: mentorid });
      if (response.code === 200) {
        setbookedslots(response.data.bookedslots);

        // Organize bookings by date and time
        const slotMap = new Map();
        response.data.bookedslots.forEach((slot) => {
          const key = `${slot.date}-${slot.slotname}`;
          if (!slotMap.has(key)) {
            slotMap.set(key, []);
          }
          slotMap.get(key).push(slot);
        });
        setBookedSlotsMap(slotMap);
      } else if (response.code === 100) {
        setbookedslots("");
      } else {
        toast.error(response.message || "Failed to fetch data");
      }
    } catch (error) {
      toast.error("An error occurred while fetching data");
    } finally {
      hideLoader();
    }
  };
  const fetchbookedslotswithoutmentorid = async (datesArray) => {
    if (!datesArray || datesArray.length === 0) return;

    try {
      showLoader();
      const response = await getMentorSlotBooking({ dates: datesArray });
      if (response.code === 200) {
        setbookedslots(response.data.bookedslots);

        // Organize bookings by date and time
        const slotMap = new Map();
        response.data.bookedslots.forEach((slot) => {
          const key = `${slot.date}-${slot.slotname}`;
          if (!slotMap.has(key)) {
            slotMap.set(key, []);
          }
          slotMap.get(key).push(slot);
        });
        setBookedSlotsMapwithoutmentorid(slotMap);
      } else if (response.code === 100) {
        return null;
      } else {
        toast.error(response.message || "Failed to fetch data");
      }
    } catch (error) {
      toast.error("An error occurred while fetching data");
    } finally {
      hideLoader();
    }
  };

  const submitButtonElement = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Call API to add PG Master
      const data = {
        slotid: selectedSlot?.id,
        slotname: selectedSlot?.time, // You might want to adjust this
        date: selectedSlot?.date,
        mentorid: mentorid,// Directly using the selected mentor object
        mentorname: name,
        // mentorid: selectedMentor?.UserID, // Directly using the selected mentor object
        // mentorname: selectedMentor?.Name,
      };
      const response = await adminBookSlot(data);

      if (response.code === 200) {
        toast.success("Slot Booked Successfully");
        setModalOpen(false);
        setselectedMentor("");
        fetchbookedslots(currentDates);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteButtonElement = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Call API to add PG Master
      const data = {
        slotid: selectedSlot?.id,
        slotname: selectedSlot?.time, // You might want to adjust this
        date: selectedSlot?.date,
        mentorid: mentorid,// Directly using the selected mentor object
        mentorname: name,
        // mentorid: selectedMentor?.UserID, // Directly using the selected mentor object
        // mentorname: selectedMentor?.Name,
      };
      const response = await adminDeleteSlot(data);

      if (response.code === 200) {
        toast.success("Slot Deleted Successfully");
        setDeleteModalOpen(false);
        setselectedMentor("");
        fetchbookedslots(currentDates);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const getCurrentDateString = () => {
    const today = new Date();
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(today.getDate()).padStart(2, "0")}`;
  };

  const generateCurrentAndNextWeekDates = () => {
    const dateArray = [];
    const currentDate = new Date();

    // Loop to generate dates for the current and next week (14 days)
    for (let i = 0; i < 14; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() + i);
      dateArray.push(
        `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${String(date.getDate()).padStart(2, "0")}`
      );
    }

    setDates(dateArray);
  };

  const closeoptionModal = () => {
    setModalOpen(false); // Function to close the modal
    setselectedMentor("");
  };

  const closeoptionDeleteModal = () => {
    setDeleteModalOpen(false); // Function to close the modal
    setselectedMentor("");

  };
  const closeAllSlotBookModal = () => {
    setBookAllSlotModalOpen(false); // Function to close the modal
    setselectedMentor("");
    setSelectedSlots({});
    fetchbookedslots(currentDates)
  };

  useEffect(() => {
    generateCurrentAndNextWeekDates();
    fetchslotTime();
    fetchmentors();
  }, []);

  useEffect(() => {
    const currentDate = getCurrentDateString();
    const index = dates.indexOf(currentDate);
    const initialPage =
      index === -1 ? 1 : Math.ceil((index + 1) / datesPerPage);
    setCurrentPage(initialPage);
  }, [dates]);

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };


  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1;
        fetchbookedslots(dates.slice((newPage - 1) * datesPerPage, newPage * datesPerPage));
        return newPage;
      });
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(dates.length / datesPerPage)) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1;
        fetchbookedslots(dates.slice((newPage - 1) * datesPerPage, newPage * datesPerPage));
        return newPage;
      });
    }
  };

  const startIndex = (currentPage - 1) * datesPerPage;
  const currentDates = dates.slice(startIndex, startIndex + datesPerPage);

  const getDayName = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", { weekday: "long" });
  };

  const styles = {
    mainContainer: {
      marginTop: "20px",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      marginBottom: "20px",
    },
    arrowButton: {
      cursor: "pointer",
      padding: "10px",
      fontSize: "16px",
      backgroundColor: "#4971b2",
      color: "#fff",
      border: "1px solid #ccc",
      margin: "0 10px",
      borderRadius: "4px",
      transition: "background-color 0.3s",
    },
    arrowButtonDisabled: {
      cursor: "not-allowed",
      padding: "10px",
      fontSize: "16px",
      backgroundColor: "#e0e0e0",
      color: "#00000091",
      border: "1px solid #ccc",
      margin: "0 10px",
      borderRadius: "4px",
    },
    calendarContainer: {
      display: "grid",
      gridTemplateColumns: `100px repeat(${currentDates.length}, 1fr)`,
      gridAutoRows: "minmax(50px, auto)",
      gap: "1px",
      flexGrow: 1,
    },
    dateHeader: {
      textAlign: "center",
      padding: "10px",
      backgroundColor: "#d6e6ff",
      border: "1px solid black",
    },
    timeLabel: {
      textAlign: "center",
      padding: "10px",
      backgroundColor: "#d6e6ff",
      border: "1px solid black",
    },
    calendarCell: {
      textAlign: "center",
      padding: '10px',
      border: '1px solid #ddd',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    bookSlotButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '14px',
      color: '#4CAF50',
      fontWeight: '500',
      cursor: 'pointer',
      marginTop: '8px',
      border: '1px solid #4CAF50', // Adds a border around the button
      padding: '8px 12px', // Adds padding for better button feel
      borderRadius: '5px', // Adds rounded corners
      textAlign: 'center',
      width: 'fit-content', // Ensures the button width fits the content
    },
    deleteSlotButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '14px',
      color: 'red',
      fontWeight: '500',
      cursor: 'pointer',
      marginTop: '8px',
      border: '1px solid red', // Adds a border around the button
      padding: '8px 12px', // Adds padding for better button feel
      borderRadius: '5px', // Adds rounded corners
      textAlign: 'center',
      width: 'fit-content', // Ensures the button width fits the content
    },
    arrow: {
      marginLeft: '5px',
      fontSize: '12px',
    },
    diagonalText: {
      position: "relative",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%) rotate(-45deg)",
      fontSize: "14px",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      pointerEvents: "none",
    },
    monthYear: {
      textAlign: "center",
      fontSize: "20px",
      margin: "10px 0",
    },
    popup: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      padding: "20px",
      border: "1px solid #ccc",
      zIndex: 1000,
    },
    popupContent: {
      textAlign: "center",
    },
    closeButton: {
      marginTop: "10px",
      backgroundColor: "#4971b2",
    },
    expiredMessage: {
      color: "#f44336", // Red color for expired message
      fontWeight: "bold",
      fontSize: "16px",
      margin: "10px 0",
      padding: "10px",
      border: "1px solid #f44336",
      borderRadius: "4px",
      backgroundColor: "#ffe6e6", // Light red background
    },
  };

  // Function to get the current month and year
  const getCurrentMonthYear = () => {
    if (currentDates.length === 0) return "";
    const firstDate = new Date(currentDates[0]);
    return firstDate.toLocaleString("default", {
      month: "long",
      year: "numeric",
    });
  };

  // const bookedSlots = new Set([
  //     // Add your booked slots with format 'YYYY-MM-DD-TIME'
  //     '2024-10-30-10:30 AM to 11:00 AM',
  // ]);

  const getSlotStyle = (index) => ({
    backgroundColor: colors[index % colors.length], // Cycle through colors
    color: "#fff",
    padding: "4px",
    borderRadius: "4px",
    margin: "2px 0",
  });
  return (
    <div>
      <title>Shubhra Viraj | Billing Courses</title>
      <div className="WrapperBox">
        <div className="Small-Wrapper" style={{ marginBottom: "10px" }}>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-between">
              <h4 className="Title">
                <Link to={-1} onClick={() => localStorage.removeItem("id")}>
                  <i className="fa fa-arrow-left"></i>
                </Link>
                &nbsp;({name}) Slot Management
              </h4>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* <h4 className="Title" style={{ marginLeft: "20px" }}>Mentor's Slot Management</h4> */}
        {/* <div className="WrapperBox"> */}
        <div className="Small-Wrapper">
          <div style={styles.monthYear}>{getCurrentMonthYear()}</div>
          <div style={styles.mainContainer}>
            <button
              onClick={handlePrevPage}
              style={
                currentPage === 1
                  ? styles.arrowButtonDisabled
                  : styles.arrowButton
              }
              disabled={currentPage === 1}
            >
              <ArrowBackIosIcon />
            </button>

            <div style={styles.calendarContainer}>
              <div style={styles.calendarCell}>
                <div style={styles.diagonalText}></div>
              </div>
              {currentDates.map((date) => (
                <div key={date} style={styles.dateHeader}>
                  <h2 style={{ margin: 0 }}>{new Date(date).getDate()}</h2>
                  <p style={{ margin: 0 }}>{getDayName(date)}</p>
                </div>
              ))}

              {slottimes?.map((slot) => (
                <React.Fragment key={slot.id}>
                  <div style={styles.timeLabel}>
                    <div>{slot.slottime.split(" to ")[0]}</div>
                    <div>to</div>
                    <div>{slot.slottime.split(" to ")[1]}</div>

                    <div style={{ marginTop: "10px" }}>
                      <input
                        type="checkbox"
                        checked={!!selectedSlots[`${slot.slottime}`]}
                        onChange={() => handleCheckboxChange(slot.slottime, slot.id)}
                        style={{ width: '24px', height: '24px', cursor: "pointer" }} // Adjust size as needed
                      />
                    </div>

                  </div>

                  {currentDates.map((date) => {
                    const bookedCount = bookedSlotsMap.get(`${date}-${slot.slottime}`)?.length || 0;
                    const availableCount = bookedSlotsMapwithoutmentorid.get(`${date}-${slot.slottime}`)?.length || 0;

                    return (
                      <div
                        key={`${date}-${slot.slottime}`}
                        style={{
                          ...styles.calendarCell,
                          backgroundColor: availableCount < 4 ? 'white' : '#D3D3D3',
                          cursor: bookedCount < 1 && availableCount < 4 ? 'pointer' : 'not-allowed', // Change cursor based on occupation
                        }}
                      >
                        {/* List booked slots first */}
                        {(bookedSlotsMap.get(`${date}-${slot.slottime}`) || []).map(
                          (booking, index) => (
                            <div key={`${date}-${slot.slottime}-${index}`}>
                              <div style={getSlotStyle(index)}>
                                {booking.mentorname}
                              </div>
                              <div
                                style={styles.deleteSlotButton}
                                onClick={() => {
                                  setSelectedSlot({
                                    date,
                                    time: slot.slottime,
                                    id: slot.id,
                                  });
                                  setDeleteModalOpen(true);
                                }}
                              >
                                Delete Slot <span style={styles.arrow}>&#9654;</span>
                              </div>
                            </div>
                          )
                        )}


                        {/* Book Slot button with right arrow, displayed only if booked slots are less than 4 */}
                        {
                          bookedCount < 1 && availableCount < 4 ? (
                            <div
                              style={styles.bookSlotButton}
                              onClick={() => {
                                setSelectedSlot({
                                  date,
                                  time: slot.slottime,
                                  id: slot.id,
                                });
                                setModalOpen(true);
                              }}
                            >
                              Book Slot <span style={styles.arrow}>&#9654;</span>
                            </div>
                          ) : (
                            bookedCount < 1 && (
                              <div style={{
                                // Remove the inner div for "Occupied" text to make the whole cell grey
                                color: 'white', // Optional: Change text color if needed
                              }}>
                                Occupied
                              </div>
                            )
                          )
                        }
                      </div>
                    );
                  })}
                </React.Fragment>
              ))}

            </div>

            <button
              onClick={handleNextPage}
              style={
                currentPage === Math.ceil(dates.length / datesPerPage)
                  ? styles.arrowButtonDisabled
                  : styles.arrowButton
              }
              disabled={currentPage === Math.ceil(dates.length / datesPerPage)}
            >
              <ArrowForwardIosIcon />
            </button>
          </div>
        </div>
      </div>

      <ModalBox
        status={isModalOpen}
        onClose={closeoptionModal}
        title={
          <div style={{ textAlign: "center", fontSize: "25px" }}>Book Slot</div>
        }
        description={
          <div>
            <p>
              <span style={{ fontWeight: "500" }}>Date:</span>{" "}
              {selectedSlot?.date}
            </p>
            <p style={{ marginBottom: "0rem" }}>
              <span style={{ fontWeight: "500" }}>Time:</span>{" "}
              {selectedSlot?.time}
            </p>
            {/* <p>Id: {selectedSlot?.id}</p> */}
          </div>
        }
        customizedFooter={
          <Button onClick={submitButtonElement}>
            {/* <Button> */}
            Yes
          </Button>
        }
      >
        {/* <div className="row">
          <div className="col-sm-12 col-form-label">
            <Select
              name="evaluator"
              placeholder="Select Mentor"
              onChange={({ target }) =>
                setselectedMentor(
                  mentors.find((mentor) => mentor.UserID === target.value)
                )
              }
              value={selectedMentor?.UserID}
              className={`form-control`}
            >
              <option value="">Select Mentor</option>
              {mentors.map((mentor) => (
                <option key={mentor.UserID} value={mentor.UserID}>
                  {mentor.Name}
                </option>
              ))}
            </Select>
          </div>
        </div> */}
      </ModalBox>
      <ModalBox
        status={isDeleteModalOpen}
        onClose={closeoptionDeleteModal}
        title={
          <div style={{ textAlign: "center", fontSize: "25px" }}>Delete Slot</div>
        }
        description={
          <div>
            <div style={{ textAlign: "center", fontSize: "18px" }}>Are you sure you want to delete this slot ?</div><br />
            <p>
              <span style={{ fontWeight: "500" }}>Date:</span>{" "}
              {selectedSlot?.date}
            </p>
            <p style={{ marginBottom: "0rem" }}>
              <span style={{ fontWeight: "500" }}>Time:</span>{" "}
              {selectedSlot?.time}
            </p>
            {/* <p>Id: {selectedSlot?.id}</p> */}
          </div>
        }
        customizedFooter={
          <Button onClick={deleteButtonElement}>
            {/* <Button> */}
            Yes
          </Button>
        }
      >
        {/* <div className="row">
          <div className="col-sm-12 col-form-label">
            <Select
              name="evaluator"
              placeholder="Select Mentor"
              onChange={({ target }) =>
                setselectedMentor(
                  mentors.find((mentor) => mentor.UserID === target.value)
                )
              }
              value={selectedMentor?.UserID}
              className={`form-control`}
            >
              <option value="">Select Mentor</option>
              {mentors.map((mentor) => (
                <option key={mentor.UserID} value={mentor.UserID}>
                  {mentor.Name}
                </option>
              ))}
            </Select>
          </div>
        </div> */}
      </ModalBox>
      <ModalBox
        status={isbookAllSlotModalOpen}
        onClose={closeAllSlotBookModal}
        title={
          <div style={{ textAlign: "center", fontSize: "25px" }}>
            Book Appointments For 2 Weeks
          </div>
        }
        description={
          <div>
            <div style={{ textAlign: "center", fontSize: "18px" }}>
              Are you sure you want to book slots for 2 weeks?
            </div>
            <br />
            {/* <p>Id: {selectedSlot?.id}</p> */}
          </div>
        }
        customizedFooter={
          <Button onClick={handleCheckboxChangeSubmit}{...submitButtonElementloading}>
            {/* Pass relevant data to handleCheckboxChangeSubmit */}
            Yes
          </Button>
        }
      />

    </div>
  );
};

export default MySlots;
